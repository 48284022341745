<template>
    <v-dialog v-model="dialog" width=600>
        <v-card>
            <v-form ref="form" v-model="formValid">

                <v-toolbar color="primary" dark dense>
                    <v-toolbar-title>Datos originales</v-toolbar-title>
                    <v-spacer/>
                    <v-btn text @click="cancela">
                        <v-icon>mdi-close-box</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-text>
                    <div v-for="(itm, id) in Object.entries(datos)" :key="id" >

                        <div class="text-subtitle-2 grey lighten-2 mt-1 pl-4 py-1 rounded-xl">{{itm[0]}}</div>
                        <div :style='{"font-size":"9pt"}'>

                            <pre v-if="itm[1] && itm[0] != 'dateCreated'" class="px-5" style="white-space: pre-wrap">{{itm[1]}}</pre>

                            <pre v-if="itm[1] && itm[0] == 'dateCreated'" class="px-5">{{moment(itm[1]).format('DD-MM-YYYY HH:mm:ss')}}</pre>

                            <div class="px-5"><pre v-if="!itm[1]">-</pre></div>
                        </div>

                    </div>
                </v-card-text>

                <v-card-actions>
                    <v-spacer/>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props:{
        dialogModal: Boolean,
        estaEval:Object,
    },

    data:()=>({
        formValid:false,
    }),

    methods:{
        async guarda(){
            this.dialog=false
        },
        cancela(){
            this.dialog=false
        },
    },

    computed:{
        dialog:{
            get(){return this.dialogModal},
            set(val){this.$emit('update:dialogModal',val)}
        },
        datos(){
            if(this.estaEval.contenidoOriginal){
                return(JSON.parse(this.estaEval.contenidoOriginal))
            } else {
                return({})
            }
        },
    },
    watch:{
        dialog(v){
            if (!v){
                this.$refs.form.reset()
            }
        }
    },

}
</script>

<style>

</style>